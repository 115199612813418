import React, { useContext } from 'react';
import { Menu } from 'antd';
import * as Icons from '@ant-design/icons';
import * as CustomIcons from '../../../shared/components/icons';
import { NavLink } from 'react-router-dom';

import { SidebarLogo, StyledSider } from '../styled';
import { useSidebar } from '../hooks';
import { IMeContext } from '../../me/context/types';
import { MeContext } from '../../me/context/context';

export const AppSidebar = () => {
  const { isOpen, activeKey } = useSidebar();
  const { can } = useContext<IMeContext>(MeContext);

  return (
    <StyledSider trigger={null} collapsible={true} width={224} collapsedWidth={46} collapsed={!isOpen}>
      <SidebarLogo>{isOpen ? <img src='/logo.svg' alt='Fikirbildir.az' /> : <img src='/logo192.png' alt='Fikirbildir.az' />}</SidebarLogo>
      <Menu theme='light' mode='inline' selectedKeys={[activeKey]}>
        <Menu.ItemGroup title='Müştərilər'>
          {can(13) && (
            <Menu.Item key='/dashboard' icon={<Icons.RocketOutlined />}>
              Piştaxta
              <NavLink to='/dashboard' />
            </Menu.Item>
          )}

          {can(74) && (
            <Menu.Item key='/complaints' icon={<Icons.MessageOutlined />}>
              Fikirlər
              <NavLink to='/complaints' />
            </Menu.Item>
          )}
          {can(56) && (
            <Menu.Item key='/companies' icon={<Icons.BranchesOutlined />}>
              Şirkətlər
              <NavLink to='/companies' />
            </Menu.Item>
          )}
          {can(26) && (
            <Menu.Item key='/users' icon={<Icons.TeamOutlined />}>
              İstifadəçilər
              <NavLink to='/users' />
            </Menu.Item>
          )}
          {can(80) && (
            <Menu.Item key='/sms-archive' icon={<Icons.MessageOutlined />}>
              SMS Arxivi
              <NavLink to='/sms-archive' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        <Menu.ItemGroup title='Bonaz'>
          {can(82) && (
            <Menu.Item key='/bonaz/conversions' icon={<Icons.ShoppingCartOutlined />}>
              Alışlar
              <NavLink to='/bonaz/conversions' />
            </Menu.Item>
          )}
          {can(81) && (
            <Menu.Item key='/bonaz/orders' icon={<Icons.ShoppingOutlined />}>
              Sifarişlər
              <NavLink to='/bonaz/orders' />
            </Menu.Item>
          )}
          {can(84) && (
            <Menu.Item key='/bonaz/withdraws' icon={<Icons.MoneyCollectOutlined />}>
              Nağdlaşdırmalar
              <NavLink to='/bonaz/withdraws' />
            </Menu.Item>
          )}
          {can(87) && (
            <Menu.Item key='/bonaz/revenue' icon={<Icons.ShopOutlined />}>
              Qazanclar
              <NavLink to='/bonaz/revenue' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        <Menu.ItemGroup title='Məzmun'>
          {can(14) && (
            <Menu.Item key='/news' icon={<Icons.FormOutlined />}>
              Xəbərlər
              <NavLink to='/news' />
            </Menu.Item>
          )}
          {can(18) && (
            <Menu.Item key='/shops' icon={<Icons.ShoppingOutlined />}>
              Mağazalar
              <NavLink to='/shops' />
            </Menu.Item>
          )}
          {can(5) && (
            <Menu.Item key='/faq' icon={<Icons.QuestionCircleOutlined />}>
              FAQ
              <NavLink to='/faq' />
            </Menu.Item>
          )}
          {can(52) && (
            <Menu.Item key='/services' icon={<Icons.ClusterOutlined />}>
              Xidmətlər
              <NavLink to='/services' />
            </Menu.Item>
          )}
          {can(44) && (
            <Menu.Item key='/countries' icon={<Icons.FlagOutlined />}>
              Ölkələr
              <NavLink to='/countries' />
            </Menu.Item>
          )}
          {can(48) && (
            <Menu.Item key='/cities' icon={<CustomIcons.CityOutlined />}>
              Şəhərlər
              <NavLink to='/cities' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
        <Menu.ItemGroup title='Ayarlar'>
          {can(93) && (
            <Menu.Item key='/advertisements' icon={<Icons.OrderedListOutlined />}>
              Reklamlar
              <NavLink to='/advertisements' />
            </Menu.Item>
          )}
          {can(89) && (
            <Menu.Item key='/complaint-templates' icon={<Icons.FileTextOutlined />}>
              Fikir şablonları
              <NavLink to='/complaint-templates' />
            </Menu.Item>
          )}
          {can(1) && (
            <Menu.Item key='/about' icon={<Icons.InfoCircleOutlined />}>
              Haqqımızda
              <NavLink to='/about' />
            </Menu.Item>
          )}
          {can(3) && (
            <Menu.Item key='/terms' icon={<Icons.FileTextOutlined />}>
              Şərtlər və qaydalar
              <NavLink to='/terms' />
            </Menu.Item>
          )}
          {can(9) && (
            <Menu.Item key='/contacts' icon={<Icons.PhoneOutlined />}>
              Bizimlə əlaqə
              <NavLink to='/contacts' />
            </Menu.Item>
          )}
          {can(36) && (
            <Menu.Item key='/status' icon={<Icons.BuildOutlined />}>
              Statuslar
              <NavLink to='/status' />
            </Menu.Item>
          )}
          {can(40) && (
            <Menu.Item key='/models' icon={<Icons.AppstoreOutlined />}>
              Modellər
              <NavLink to='/models' />
            </Menu.Item>
          )}
        </Menu.ItemGroup>
      </Menu>
    </StyledSider>
  );
};
