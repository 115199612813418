import flatten from 'flat';

export const object2Search = (object: Record<string, any>): string => {
  const flattened = flatten(object);
  const arrayedObject = Object.entries(flattened);
  return arrayedObject.length
    ? arrayedObject
        .filter(([_, value]) => value !== null && value !== undefined)
        .reduce((acc, [key, value], index) => {
          return acc + (index ? '&' : '?') + `${key}=${value}`;
        }, '')
    : '';
};
