import { Auth, LoginFormData, LoginFormErrors, Me } from '../types';
import { MeMappers } from '../mappers';

import { ApiResponse } from '../../../shared/utils/api-response';
import { urlMaker } from '../../../shared/utils/url-maker';
import { caller } from '../../../shared/utils/caller';
import Cookies from 'js-cookie';

export class MeService {
  public static async getMe() {
    const url = urlMaker('/api/admin/users/data');

    try {
      const response = await caller(url);

      if (response.ok) {
        return new ApiResponse<Me>(200, MeMappers.meFromApi(await response.json()));
      }

      return new ApiResponse<string>(400, 'Məlumatlar əldə edilə bilmədi.');
    } catch (e) {
      return new ApiResponse<string>(500, 'Şəbəkə ilə əlaqə qurula bilmədi.');
    }
  }

  public static async logout() {
    const url = urlMaker('/api/admin/logout');

    try {
      Cookies.remove('refreshToken');
      Cookies.remove('accessToken');
      Cookies.remove('tokenType');
      const response = await caller(url, { method: 'POST' });

      if (response.ok) {
        return new ApiResponse(200);
      }

      return new ApiResponse(400, 'Əməliyyat aparıla bilmədi.');
    } catch (e) {
      return new ApiResponse<string>(500, 'Şəbəkə ilə əlaqə qurula bilmədi.');
    }
  }

  public static async login(formData: LoginFormData) {
    const url = urlMaker('/api/admin/login');
    const body = new FormData();
    const mappedFormData = MeMappers.loginFormToApi(formData);

    Object.entries(mappedFormData).forEach(([key, value]) => body.append(key, value));

    try {
      const response = await caller(url, { method: 'POST', body });

      if (response.ok) {
        return new ApiResponse<Auth>(200, MeMappers.loginFormSuccessFromApi(await response.json()));
      } else if (response.status === 400 || response.status === 422) {
        const { error, errors } = await response.json();

        if (error === 'invalid_grant') {
          return new ApiResponse<string>(401, 'İstifadəçi məlumatları yalnış daxil edilib.');
        } else if (errors) {
          return new ApiResponse<LoginFormErrors>(422, MeMappers.loginFormErrorsFromApi(errors));
        }
      } else if (response.status === 401) {
        const { message } = await response.json();

        if (message === 'Blocked') {
          return new ApiResponse<string>(403, 'Sistemə girişinizə müvəqqəti olaraq məhdudiyyət qoyulmuşdur.');
        }
      }

      return new ApiResponse<string>(400, 'Əməliyyat aparıla bilmədi.');
    } catch (e) {
      return new ApiResponse<string>(500, 'Şəbəkə ilə əlaqə qurula bilmədi.');
    }
  }
}
