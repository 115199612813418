import React from 'react';

import { MainRouter } from './router';
import { MeProvider } from './modules/me/context/context';
import { StyledAntdGlobal } from './shared/styled/antd';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
  return (
    <BrowserRouter>
      <MeProvider>
        <StyledAntdGlobal />
        <MainRouter />
      </MeProvider>
    </BrowserRouter>
  );
};

export default App;
