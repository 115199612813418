import React, { lazy, Suspense, useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { SuspenseSpin } from '../shared/styled/spin';

import { AppLayout } from '../modules/layout/containers';
import { IMeContext } from '../modules/me/context/types';
import { MeContext } from '../modules/me/context/context';

const DashboardRouter = lazy(() => import('../modules/dashboard/router'));
const MeModule = lazy(() => import('../modules/me'));
const AboutModule = lazy(() => import('../modules/about'));
const ContactsModule = lazy(() => import('../modules/contacts'));
const TermsModule = lazy(() => import('../modules/terms'));
const FaqModule = lazy(() => import('../modules/faq'));
const ShopsModule = lazy(() => import('../modules/shops'));
const NewsModule = lazy(() => import('../modules/news'));
const StatusModule = lazy(() => import('../modules/status'));
const ModelsModule = lazy(() => import('../modules/models'));
const UsersModule = lazy(() => import('../modules/users'));
const CitiesModule = lazy(() => import('../modules/cities'));
const CountriesModule = lazy(() => import('../modules/countries'));
const ServicesModule = lazy(() => import('../modules/services'));
const CompaniesModule = lazy(() => import('../modules/companies'));

const AdvertisementRouter = lazy(() => import('../modules/advertisements/router/advertisements.router'));
const AdvertisementModalRouter = lazy(() => import('../modules/advertisements/router/advertisements.modal-router'));

const ComplaintsRouter = lazy(() => import('../modules/complaints/router/complaints.router'));
const ComplaintsModalRouter = lazy(() => import('../modules/complaints/router/complaints.modal-router'));

const SmsArchiveModule = lazy(() => import('../modules/sms-archive'));
const BonazModule = lazy(() => import('../modules/bonaz'));
const ComplaintTemplatesModule = lazy(() => import('../modules/compaint-templates'));

export const MainRouter = () => {
  const me = useContext<IMeContext>(MeContext);
  const location = useLocation<{ background: any } | undefined>();

  return me.state.user.loading ? (
    <SuspenseSpin />
  ) : !!me.state.user.data ? (
    <AppLayout>
      <Suspense fallback={<SuspenseSpin />}>
        <Switch location={location.state?.background || location}>
          {me.can(13) && <Route path='/dashboard' component={DashboardRouter} />}
          {me.can(1) && <Route path='/about' component={AboutModule} />}
          {me.can(3) && <Route path='/terms' component={TermsModule} />}
          {me.can(9) && <Route path='/contacts' component={ContactsModule} />}
          {me.can(5) && <Route path='/faq' component={FaqModule} />}
          {me.can(18) && <Route path='/shops' component={ShopsModule} />}
          {me.can(14) && <Route path='/news' component={NewsModule} />}
          {me.can(56) && <Route path='/companies' component={CompaniesModule} />}
          {me.can(36) && <Route path='/status' component={StatusModule} />}
          {me.can(40) && <Route path='/models' component={ModelsModule} />}
          {me.can(26) && <Route path='/users' component={UsersModule} />}
          {me.can(44) && <Route path='/countries' component={CountriesModule} />}
          {me.can(48) && <Route path='/cities' component={CitiesModule} />}
          {me.can(52) && <Route path='/services' component={ServicesModule} />}
          {me.can(74) && <Route path='/complaints' component={ComplaintsRouter} />}
          {me.can(80) && <Route path='/sms-archive' component={SmsArchiveModule} />}
          {me.can(93) && <Route path='/advertisements' component={AdvertisementRouter} />}
          <Route path='/bonaz' component={BonazModule} />
          {me.can(89) && <Route path='/complaint-templates' component={ComplaintTemplatesModule} />}
          <Redirect to='/dashboard' />
        </Switch>
        <Switch>
          <Route path='/complaints' component={ComplaintsModalRouter} />
          <Route path='/advertisements' component={AdvertisementModalRouter} />
        </Switch>
      </Suspense>
    </AppLayout>
  ) : (
    <Suspense fallback={<SuspenseSpin />}>
      <Switch>
        <Route path='/' component={MeModule} />
      </Switch>
    </Suspense>
  );
};
