import React, { createContext, FC, useReducer } from 'react';
import { meContextReducer } from './reducer';
import { initialMeContextState } from './state';
import { IMeContext } from './types';
import { useBootstrapMeContext } from './hooks';

export const MeContext = createContext<IMeContext>({
  state: initialMeContextState,
  dispatch: () => null,
  can: (permission: number) => false,
});

export const MeProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(meContextReducer, initialMeContextState);

  useBootstrapMeContext(state, dispatch);

  const can = React.useCallback(
    (permission: number) => {
      return state.user.data?.permissions.includes(permission) || false;
    },
    [state.user.data?.permissions],
  );

  return <MeContext.Provider value={{ state, dispatch, can }}>{children}</MeContext.Provider>;
};
