import { LoginFormData, Me, LoginApiFormData, LoginApiFormErrors, LoginFormErrors, Auth } from '../types';
import { staticFileUrl } from '../../../shared/utils/url-maker';

export class MeMappers {
  public static meFromApi(raw: any): Me {
    return {
      id: raw.data.id,
      firstName: raw.data.name,
      lastName: raw.data.surname,
      email: raw.data.email,
      phoneNumber: raw.data.number,
      profilePhoto: raw.data.filename ? staticFileUrl(raw.data.filename) : '/images/avatar.jpg',
      birthDate: raw.data.birth_date,
      gender: raw.data.gender,
      permissions: raw.data.permissions.map((p) => p.operation_id),
    };
  }

  public static loginFormToApi(formData: LoginFormData): LoginApiFormData {
    return {
      username: formData.username,
      password: formData.password,
    };
  }

  public static loginFormErrorsFromApi(errors: LoginApiFormErrors): LoginFormErrors {
    return {
      username: errors.username,
      password: errors.password,
    };
  }

  public static loginFormSuccessFromApi(response: any): Auth {
    return {
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
      tokenType: response.token_type,
      expiresIn: response.expires_in,
    };
  }
}
